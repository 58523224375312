
import AdyenCheckout from '@adyen/adyen-web';
import '@adyen/adyen-web/dist/adyen.css';
import ApiService from '@/core/services/ApiService';
import { defineComponent, ref } from 'vue';

export default defineComponent({
	name: 'adyen-create-payment',
	components: {},

	setup() {
		const formData = ref({
			email: '',
			pricePlan: '',
			amount: 0
		});
		const sessionData = ref({
			session: {},
			clientKey: process.env.VUE_APP_ADYEN_KEY || '',
			environment: 'test',
			onPaymentCompleted: (result, component) => {
				console.log('RESSULT', result, component);
			},
			onError: (error, component) => {
				console.log('ERROR', error, component);
			}
		});
		const loading = ref(false);

		const createCheckout = async () => {
			const checkout = await AdyenCheckout(sessionData.value);
			// Create an instance of Drop-in and mount it to the container you created.
			const dropinComponent = checkout
				.create('dropin')
				.mount('#dropin-container');
		};

		const submit = async () => {
			if (formData.value.email && formData.value.amount) {
				loading.value = true;

				const data: any = {
					email: formData.value.email,
					amount: formData.value.amount,
					pricePlan: formData.value.pricePlan
				};

				ApiService.post(`Checkout/CreatePaymentSession`, data)
					.then(res => {
						loading.value = false;
						console.log(res.data);
						sessionData.value.session = {
							id: res.data.id,
							sessionData: res.data.sessionData
						};
					})
					.then(async () => {
						await createCheckout();
					})
					.catch(err => {
						console.log(err);
						loading.value = false;
					});
			}
		};

		return {
			loading,
			formData,
			submit
		};
	}
});
