
import { defineComponent } from 'vue';
import AdyenCreatePayment from '@/components/adyen/AdyenCreatePayment.vue';

export default defineComponent({
	name: 'adyen',
	components: { AdyenCreatePayment },
	setup() {
		return;
	}
});
